import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);

export const Header = (props) => {
  

  return (
    
    <header id="header">
       <AutoplaySlider
       bullets={false}
        // animation="foldOutAnimation"
        play={true}
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={4000}
       >
    <div> <img src={"img/h1.png"} alt="" width={1520} /></div>
    <div> <img src={"img/h2.png"} alt="" width={1520} /></div>
    <div style={{marginTop:90}}> <img src={"img/h3.png"} alt="" width={1520} /></div>
    <div style={{marginTop:90}}> <img src={"img/h4.jpeg"} alt="" width={1520} /></div>
    <div style={{marginTop:90}}> <img src={"img/h5.jpeg"} alt="" width={1520} /></div>
    {/* <div style={{marginTop:90}}> <img src={"img/h6.jpeg"} alt="" width={1520} /></div> */}
  </AutoplaySlider>
      <div>
      <div className="col-md-10 col-md-offset-1 section-title">
      <h1 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>Ensuring Your Child Feels Their Best Is Important </h1>
        <h6>Empowering children starts with Early Intervention. While every child is different and has different learning habits and natures, there is no denying that children are impressionable. The nurturing and the care must begin at a young age.
        </h6>
        <div className="row" style={{marginTop:40}}>
        <div className="col-xs-12 col-md-6">
        {/* <h1>HealthNovo Saksham</h1>
              <img src="img/skmlogo.png" className="img-responsive" alt=""/>  */}
           
          <h3> Detection...Intervention...Prevention... </h3>
        <p>Despite the growing awareness around mental health, India still lacks the resources to ensure proper mental healthcare for youngsters and teenagers. At Healthnovo Saksham, we are on a mission to make mental healthcare services affordable and accessible for young minds at the right time.<br/><br/>
        Right mental health support at the right time leads to enhanced emotional intelligence for children and increased ability to shape the child's overall wellbeing.</p>
          </div>
          
          <div className="col-xs-12 col-md-6">
          <img src="img/home2.jpeg" width={500} height={300} alt=""/> 
          </div>
      </div>
       <div className="row" style={{marginTop:40}}>
       <div className="col-xs-12 col-md-6">
        <img src="img/home3.jpg" width={500} height={300} alt=""/> 
        </div>
       <div className="col-xs-12 col-md-6">
       <h3>How does Saksham make mental health care accessible & affordable?</h3>
        <p>Our services include a list of amenities needed to enhance a child's overall well-being and can be availed by parents, school authorities, caretakers, and children.<br/><br/>
Saksham is backed by the solid clinical structure from Healthnovo, which is working in the preventive physical healthcare services sector. Saksham provides preventive solutions to mental/ behavioural problems faced by young minds and their parents.
</p>
        </div>

       </div>
        </div>
     </div>
      {/* <div>
        <h1>HealthNovo Saksham</h1>
        <div style={{display:'flex',justifyContent:'space-around'}}>
        <h6>Early Intervention, Lasting Impact. 
        </h6>
        <img src={"img/skmlogo.png"} alt="" width={55} />
        </div>
        <div>
        <img src={"img/home1.jpeg"} alt="" width={200} height={200}/>
        </div>
       
      </div> */}
    
          {/* <div style={{textAlign:'start',margin:20}}>
          <h1>How does Saksham make mental health care accessible & affordable?</h1>
        <h6>Our services include a list of amenities needed to enhance a child's overall well-being and can be availed by parents, school authorities, caretakers, and children.<br/><br/>
Saksham is backed by the solid clinical structure from Healthnovo, which is working in the preventive physical healthcare services sector. Saksham provides preventive solutions to mental/ behavioural problems faced by young minds and their parents.
</h6>
<img src="img/home3.jpg" className="img-responsive" alt=""/> 
          </div> */}
    </header>
  );
};
