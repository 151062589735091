import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="">
        <div className="section-title">
          <h2>Impact Of Our Services</h2>
        </div>
        <div className="row">
          {/* <div className="col-md-4">
          <img src="img/servicemain.png" className="img-responsive" alt="" style={{marginTop:'60px'}}/>
          </div> */}
          {/* <div className="col-md-8"> */}
          {/* <div className="row"> */}
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6">
                  {" "}
                  <div className="servicebox">
                  {/* <img src="img/servicemain.png" className="img-responsive" alt=""/> */}
                  {/* <i className={d.icon}></i> */}
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                   </div>  
                  </div>
                </div>
              ))
            : "loading"}

        {/* </div> */}
       </div>

        {/* </div> */}
       
      </div>
    </div>
  );
};
