// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,WAAW;EACb;EACA;;;;;;IAME,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":["@media screen and (max-width: 400px) {\r\n  #features {\r\n    padding: 20px;\r\n    width: 111%;\r\n  }\r\n  #about,\r\n  #services,\r\n  #testimonials,\r\n  #team,\r\n  #contact,\r\n  #footer {\r\n    width: 111%;\r\n  }\r\n\r\n  #portfolio {\r\n    width: 110%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
