import React from "react";

export const Community = () => {
  return (
    <div id="skcommunity">
      <div className="container">
        <div className="section-title text-center">
          <h2>Saksham Community</h2>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">

          <h1 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>Careers</h1>
          <h3>Coming soon...</h3>
          <h1 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>Partnerships</h1>
          <h3>Coming soon...</h3>
          <h1 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>Volunteer With Us</h1>
          <p>At Healthnovo Saksham, we are looking for like-minded individuals and organizations who are committed to making mental health accessible and affordable for all. In case your interests align with our vision, you can reach out to us. </p>
          </div>
   
        </div>
      </div>
    
  );
};
