import React from "react";
import Content from "./ReadMore";
export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Services</h2>
          <h1 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>Beyond Awareness: How Healthnovo Saksham Creates Actionable Solutions for Neurodiversity</h1>
          
          <h5>Mental health for children is not just limited to children. Their overall welfare includes empowering their parents and caregivers in the process. That is why, Healthnovo Saksham offers these comprehensive mental health services that ensure the student is nurtured and empowered.
         </h5> 
        </div>
        <div className="row" style={{boxShadow: '0px 0px 10px 0px rgba(97, 232, 82, 0.75)',
    marginTop: 20,
    background:'rgb(245, 245, 245)'}} >
        <h3>Counselling</h3>
          <p>At Saksham, we offer a comprehensive support system for young minds and their caregivers. Confidential one-on-one therapy provides a safe space for exploration, while group sessions foster connection and shared experiences. Our career guidance helps young minds discover their strengths and make confident choices about their future, all without feeling peer pressure or anxiety.
          </p>
        <div className="col-xs-12 col-md-6" style={{padding:20}}>
        <img src="img/indi.jpeg" width={300} height={250} alt="" style={{borderRadius:20}}/> <br/>
          <h6 style={{color:'#2da2db'}}>Individual Counselling</h6>
          <p>
         <strong>Confidential One-on-One Support :</strong> Tailored to students' needs, providing a safe space for open dialogue and personalised guidance.
          </p>
          </div>
          <div className="col-xs-12 col-md-6" style={{padding:20}}>
  
  <img src="img/grp.jpeg" width={300} height={250} alt="" style={{borderRadius:20}}/> 
  <h6 style={{color:'#2da2db'}}>Group Counselling</h6>
  <p><strong>Connect and Support :</strong> Opportunities for students to connect, share experiences, and offer mutual support within a supportive group setting.</p>
  </div>
        </div>
        <div className="row" style={{boxShadow: '0px 0px 10px 0px rgba(97, 232, 82, 0.75)',
    marginTop: 20,
    background:'rgb(245, 245, 245)'}}>
        <div className="col-xs-12 col-md-6" style={{padding:20}}>
          <h3> Shadow Teacher Assistance</h3>
          <img src="img/shadow.jpeg" width={300} height={250} alt="" style={{borderRadius:20,marginTop:30,marginBottom:20}}/> 
          <p>Personalized support for young minds with diverse learning needs that ensures the children do not hesitate to connect with their well-being.
          </p>
          </div>
          <div className="col-xs-12 col-md-6" style={{padding:20}}>
          <h3> Workshop for Teachers and Caregivers</h3>
          <img src="img/workshop.jpeg" width={300} height={250} alt="" style={{borderRadius:20}}/> 
          <p>We conduct sessions and workshops for caregivers and support staff on stress management and coping skills to help them help young ones be emotionally strong in the face of distress.</p>
         
          </div>
        </div>
      </div>
    </div>
    
  );
};
