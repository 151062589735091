// Filename - components/ReadMore.js

import React, { useState } from "react";

const ReadMore = ({ children }) => {
	const text = children;
	const [isReadMore, setIsReadMore] = useState(true);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	return (
		<h5>
			{isReadMore ? text.slice(0, 100) : text}
			<span
				onClick={toggleReadMore}
				className="read-or-hide"
				style={{ color: "#8bc542" }}
			>
				{isReadMore ? "...read more" : " show less"}
			</span>
		</h5>
	);
};

const Content = (props) => {

	return (
		<div style={{marginBottom:'25px'}}>
			{/* <h2> */}
				<ReadMore>
					{props.data}
				</ReadMore>
			{/* </h2> */}
		</div>
	);
};

export default Content;
