import React from "react";

export const About = (props) => {

  return (
    <div id="about" className="text-center"> 
        <div className="container">
      <div className="col-md-10 col-md-offset-1 section-title">
      <h2>About Us</h2>
        </div>
      <div className="row">
      {/* <h2 style={{textAlign:'center'}}>About Healthnovo Saksham</h2> */}
      <div style={{background:'#f5f5f5',borderRadius:15,boxShadow:' 0 0 10px rgba(0, 0, 0, 0.50)'}} className="col-md-12 section-title">
        
        <h3 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>Saksham: Where Neurodiversity Becomes Strength</h3>
        <h5 style={{textAlign:'justify'}}>At Saksham, we believe neurodiversity isn't a limitation, it's a strength. We see the unique talents and perspectives that neurodiverse children bring to the world. But navigating a world built for a different way of thinking can be challenging. That's where we come in.<br/><br/>
We bridge this gap by: <br/><br/>
- <strong>Building a Network of Experts :</strong> Our team of passionate and highly-trained  professionals is dedicated to supporting neurodiverse children.<br/>
- <strong>Empowering Through Education :</strong> We fight stigma by providing factual information and resources for parents and educators.<br/>
- <strong>Early Intervention is Key :</strong> We believe in addressing challenges early on to maximize a child's potential.<br/><br/>
With counselling services for children as well as their parents, we ensure a comprehensive approach to your child's emotional well-being. Through Saksham, we intend to bring seasoned professionals to work with neurodiverse young minds and empower them to lead successful lives.
</h5>
</div>

      </div>
      <div className="row">
      <div className="col-xs-12 col-md-6">
      <h1 style={{color:'#2da2db',fontWeight:700}}>Vision</h1>
      <h5>Our vision is to foster a world where every child has an opportunity to thrive emotionally and mentally. We strive to create an inclusive environment where mental well-being is prioritized, and every child is equipped with the tools and support they need to navigate life's challenges with resilience and confidence.<br/><br/>
The aim at Healthnovo Saksham is to:<br/></h5>
<ul>
  <li><h5><strong>Make mental healthcare affordable for young minds</strong></h5></li>
  <li><h5><strong>Implement preventative measures to cultivate emotional intelligence</strong></h5></li>
  <li><h5><strong>Empower children, parents, and caregivers for an all-round growth journey</strong></h5></li>
  <li><h5><strong>Create programs that ensure the overall development of a child</strong></h5></li>
</ul> 
<div style={{marginTop:70,boxShadow:'0px 0px 30px 0px rgba(97, 232, 82, 0.75)',borderRadius:20}}>
<img src="img/mission.jpg" width={550}  height={300} alt=""/> 
</div>

   </div>
      <div className="col-xs-12 col-md-6">
        <div style={{marginTop:80,boxShadow:'0px 0px 30px 0px rgba(97, 232, 82, 0.75)',borderRadius:20}}>
    
        <img src="img/vision.jpg" width={550} height={300} alt=""/> 
        </div>
        <div style={{marginTop:70}}>
        <h1 style={{color:'#2da2db',fontWeight:700}}>Mission</h1>
    <h5>Making mental health, affordable and accessible is not just a good-to-have; it is a must-have. At Healthnovo Saksham, we understand that mental healthcare is altogether more important for young minds. Our mission is to build a system that fosters mental health services for young minds through child welfare, caregiver empowerment, and preventative measures</h5>
     
        </div>
      </div>
      </div>
      <div className="row">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h1 style={{color:'#2da2db',textAlign:'center',fontWeight:700}}>Know Our Team</h1>
          <h5>
          Meet the people who are on a mission to make mental well-being accessible for all.
          </h5>
        </div>
        
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6 col-sm-6 team">
                  <div>
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                     
                     {/* <p onClick={handleShow}>{d.job}</p> */}
                    </div>
                  </div>
                  <div>
                  <div className="chlist">
                      <span style={{fontSize:16}}>{d.des}</span>
                  </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div className="col-md-8 col-md-offset-2" style={{marginTop:20}}>
          <h1 style={{color:'#2da2db',textAlign:'center',fontWeight:700}}>Our Values</h1>
        </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="chlist1">
          <h6>Empathy</h6>
          <p>We understand each child's unique journey.
          </p>
         </div>
         <div className="chlist1">
          <h6>Confidentiality</h6>
          <p> We create a safe space for open communication.
          </p>
         </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="chlist1">
          <h6>Authenticity</h6>
          <p>We celebrate the individuality of every young mind.
          </p>
         </div>
         <div className="chlist1">
          <h6>Integrity</h6>
          <p>We hold ourselves to the highest ethical standards
          </p>
         </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-xs-12">
                 <span className="sideline"></span>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="chlist1">
          <h6>Honest and Direct Communication</h6>
          <p>We believe in clear and transparent communication with families and professionals.
          </p>
         </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-xs-12">
                <span className="sideline"></span>
                </div>
        {/* <div className="row">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Our Values</h2>
        </div>
        <div className="col-lg-6 col-sm-6 col-xs-12">
        <div className="chlist">
          <h6>Empathy</h6>
          <p>We understand each child's unique journey.
          </p>
         </div>
         <div className="chlist">
          <h6>Confidentiality</h6>
          <p> We create a safe space for open communication.
          </p>
         </div>
         
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12">
        <div className="chlist">
          <h6>Authenticity</h6>
          <p>We celebrate the individuality of every young mind.
          </p>
         </div>
         <div className="chlist">
          <h6>Integrity</h6>
          <p>We hold ourselves to the highest ethical standards
          </p>
         </div>
          </div>
          <div className="chlist">
          <h6>Honest and Direct Communication</h6>
          <p>We believe in clear and transparent communication with families and professionals.
          </p>
         </div>
        </div> */}
        <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <h1 style={{color:'#2da2db',textAlign:'center',fontWeight:700}}>Client Testimonials</h1>
          <h3>Coming soon...</h3>
        </div>
        </div>
        <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <h1 style={{color:'#2da2db',textAlign:'center',fontWeight:700}}>In The Media</h1>
          <h3>Coming soon...</h3>
        </div>
        </div>
        <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <h1 style={{color:'#2da2db',textAlign:'center',fontWeight:700}}>Confidentiality</h1>
          
        </div>
        <div className="col-md-8">
        <h3 style={{textAlign:'start',fontWeight:600}}> You are Safe With Us...</h3>
          <h5>At Saksham, your child's safety and well-being are our top priorities. We understand that entrusting us with your child's care is a significant decision, and we take that responsibility very seriously. 
          </h5>
        </div>
        <div className="row">
        <div className="col-xs-12 col-md-6">
         <div style={{textAlign:'start'}}>
         <h5>
         Here's how we ensure a safe and supportive environment:<br/>
          <strong>1. Confidentiality :</strong> We adhere to strict ethical guidelines regarding confidentiality. All information shared with us, whether from parents, children, or professionals, is kept confidential unless there is a legal or ethical obligation to disclose it.<br/>
<strong>2. Parental Consent :</strong> We obtain informed consent from parents or legal guardians before providing any services to a child. This ensures you are fully aware of the support we offer and actively involved in your child's care journey.<br/>
<strong>3. Priority on Safety :</strong> In rare situations, if we suspect a child is experiencing abuse or neglect, we are mandated by law to report it to the appropriate authorities or child protection NGOs. Our goal is to prioritize your child's safety and well-being, and we will work with you to ensure the necessary support systems are in place.<br/>
<strong>4. Open Communication :</strong> We believe in maintaining open and transparent communication with families. We encourage you to ask questions, share concerns, and participate actively in your child's care plan.<br/>
If you have any questions, please feel free to reach out to us.
          </h5> 
         </div>
          </div>
          <div className="col-xs-12 col-md-6">
          <img src="img/youaresafe.jpg" width={500} height={400} alt=""/> 
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
