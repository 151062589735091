import React,{useState, useRef } from "react";

const faqs = [
    {
        id: 1,
        header: "Am I experiencing stress?",
        text: `Stress feels like you have too much to handle. You might feel worried or uncomfortable about something and it can make you feel tense, upset, or even get headaches or stomach aches.`
    },
    {
        id: 2,
        header: "How do I identify I have anxiety?",
        text: `If you often feel very worried, nervous, or afraid about things that might not seem scary to others, and these feelings are strong enough to interfere with your daily life, it might be anxiety.`
    },
    {
        id: 3,
        header: "What is ADHD?",
        text: `ADHD stands for Attention Deficit Hyperactivity Disorder. It’s when someone finds it really hard to focus, sit still, or think before acting. They might feel like their motor is running all the time.`
    },
    {
        id: 4,
        header: "How do you define ASD?",
        text: `ASD stands for Autism Spectrum Disorder. It affects how a person acts, interacts with others, communicates, and learns. Everyone with ASD is different; some might need a lot of help in their daily lives, while others need less.`
    },
    {
        id: 5,
        header: "What is a slow learner?",
        text: `A slow learner is someone who learns at a pace slower than their friends. This doesn’t mean they can’t learn! They might just need a bit more time or different ways to learn things.`
    }
]
const faqs1 = [
    {
        id: 6,
        header: "How do you figure someone is suffering from depression?",
        text: `Depression can make someone feel very sad or lose interest in things they used to like. They might feel tired all the time, not want to eat, or have trouble sleeping. If someone feels this way most of the time, they might be dealing with depression.`
    },
    {
        id: 7,
        header: " How do I know I am being bullied?",
        text: `If someone is being mean to you on purpose, repeatedly, like by teasing, hurting, or threatening you, that’s bullying. It can make you feel scared, hurt, or embarrassed.`
    },
    {
        id: 8,
        header: "What is OCD?",
        text: `OCD, or Obsessive-Compulsive Disorder, involves having constant troubling thoughts (obsessions) and using actions (compulsions) to try to control those thoughts. An example might be washing your hands many times to try to feel clean.`
    },
    {
        id: 9,
        header: " How do you identify if your child suffers from a learning disability?",
        text: `A learning disability might be the reason if your child has a tough time learning and using certain skills like reading, writing, or math. They might be trying really hard but still finding school work really difficult.`
    },
    {
        id: 10,
        header: "Is it possible to keep my identity confidential while seeking therapy?",
        text: `Yes, when you talk to a therapist, what you say can stay private. Therapists have rules they follow to keep your information safe and confidential, which means they won’t tell others what you’ve shared without your permission`
    },
  
]

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { header, id, text } = faq;

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title">{header}</h5>
                    <i className="fa fa-chevron-down rc-accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0'>{text}</p>
                </div>
            </div>
        </div>
    )
}
const AccordionItem1 = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq1 } = props;
    const { header, id, text } = faq1;

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title">{header}</h5>
                    <i className="fa fa-chevron-down rc-accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0'>{text}</p>
                </div>
            </div>
        </div>
    )
}
export const Resources = (props) => {
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    
  return (
    <div id="resources">
      <div className="container">
        <div className="section-title text-center">
          <h2>Resources</h2>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">

         <h1 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>24*7 Helpline : +91 7483394349</h1>
          <p>If you are someone who is feeling overwhelmed by stress or have concerns weighing on your mind, reach out to us on our helpline. Our trained professionals and volunteers offer 24/7 confidential support, providing non-judgmental listening and emotional assistance to anyone in need.
          </p>
         <h1 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>FAQs</h1>
          {/* <p>
          Am I experiencing stress?<br/>
How do I identify I have anxiety?<br/>
What is ADHD?<br/>
How do you define ASD?<br/>
What is a slow learner?<br/>
How do you figure someone is suffering from depression?<br/>
How do I know I am being bullied?<br/>
What is OCD?<br/>
How do you identify if your child suffers from a learning disability?<br/>
Is it possible to keep my identity confidential while seeking therapy?<br/>

          </p> */}
          <>
            <div className="container-fluid mt-5 mb-5">
                <div className="row justify-content-center">
                    <div className="col-md-6 mt-2">
                        <div className="card">
                            <div className="card-body">
                              {/* <h4 className="form-heading mb-4 text-primary text-center mt-3">React Accordion</h4> */}
                                {faqs.map((faq, index) => {
                                     return (
                                            <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-2">
                        <div className="card">
                            <div className="card-body">
                              {/* <h4 className="form-heading mb-4 text-primary text-center mt-3">React Accordion</h4> */}
                                {faqs1.map((faq1, index) => {
                                     return (
                                            <AccordionItem1 key={index} active={active} handleToggle={handleToggle} faq1={faq1} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
         <h1 style={{color:'2da2db',textAlign:'center',fontWeight:700}}>Our Blogs</h1>
         <h3>Coming soon...</h3>
          </div>
          <div className="row">
          
         {/* <div className="col-md-6 mt-2">
         <div className="blogcard">
            <p style={{fontSize:18,fontWeight:600}}>Little by little, the gap gets bridged.<br/>
Last week, we conducted a workshop with Udayan Care, an organization that empowers young girls with mentorship support, as well as life skills and employability training.<br/>
Even though mental health has found mainstream support, I witnessed how pivotal the resources for the well-being of young Indians are. Our 2-hour session provided the 55 girls an introduction to mental well-being.<br/>
During the introduction, it was clear that many of the girls were curious yet hesitant to discuss mental health. However, as the session progressed, a sense of openness and vulnerability emerged. It was truly inspiring to witness these young women begin to break down the stigma surrounding mental well-being.<br/>
I believe such workshops ensure that the gap is never too wide to be bridged and can make a real difference.</p>
            </div>
            </div> */}
         </div>
          {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="row">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"} */}
        </div>
      </div>
    
  );
};
